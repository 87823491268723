.carousel-item {
    height: 75vh;
    /* vh stands for viewport height and 75 means 75% of the height of the viewport */
}

.carousel-caption {
    background-image: radial-gradient(circle at 50% 50%, rgba(200,200,200,.75), transparent);
    color: #000 !important;
}

@media only screen and (max-width: 1023px){
    .carousel-item {
        height: unset;
    }
}